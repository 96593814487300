.topContainer {
  // width: 100vw;
  // left: 0;
  background-color: #e7f2e9;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}

@media screen and (max-width: 576px) {
  .topContainer {
    // img {
    //   width: 24px;
    //   height: 24px;
    // }

    .topContainer-text {
      font-size: 14px;
    }
  }
}

